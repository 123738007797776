/* import the necessary Bootstrap files */

$enable-shadows: true;
$enable-gradients: true;
$enable-responsive-font-sizes: true;
// we need these before we modify themes
// https://stackoverflow.com/a/50253707/416626
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
$theme-colors: map-merge(
    $theme-colors,
    (
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "orange": $orange,
    "teal": $teal
    )
);
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";
// mapbox-gl isn't scss, but the compiler seems OK with it anyway
@import "../../node_modules/mapbox-gl/dist/mapbox-gl";

.honly {
    border-color: #212529 !important;
    border: 1px solid;
}

.ztop {
    z-index: 1;
}

.gamechart {
    background-color: #ebe3de;
}

.strikethrough {
        text-decoration: line-through;
}
